import * as Highcharts from 'highcharts';
import { CommunityCountsChartType } from './all.type';

// 门禁授权统计标签
const countsNav = [{
    label: WordList.ProperAllTextResident,
    key: 'Resident',
    path: ''
}, {
    label: WordList.OfficeVisitor,
    key: 'TempKey',
    path: ''
}, {
    label: WordList.ProperAllTextDelivery,
    key: 'Delivery',
    path: ''
}, {
    label: WordList.OfficeStaff,
    key: 'Staff',
    path: ''
}];

const reminderData = [{
    title: WordList.ProperAllTextOfflineDevices,
    icon: require('@/assets/image/Offline_Devices.png'),
    enable: true,
    name: 'Offline',
    type: 'red',
    path: '/propertyIndex/device?Status=0'
}, {
    title: WordList.ProperAllTextInactivatedResidents,
    icon: require('@/assets/image/residents.png'),
    enable: true,
    name: 'Inactivated',
    type: 'yellow',
    path: '/propertyIndex/resident?Active=2'
}, {
    title: WordList.ProperAllTextExpiringResidents,
    icon: require('@/assets/image/residents.png'),
    enable: true,
    name: 'Expiring',
    type: 'orange',
    path: '/propertyIndex/resident?Active=3'
}, {
    title: WordList.ProperAllTextExpiredResidents,
    icon: require('@/assets/image/residents.png'),
    enable: true,
    name: 'Expired',
    type: 'purple',
    path: '/propertyIndex/resident?Active=4'
}];

const infoData = [{
    title: WordList.DarshbordBuild,
    icon: require('@/assets/image/building.png'),
    enable: false,
    name: 'Building',
    path: ''
}, {
    title: WordList.ProperAllTextApartments,
    icon: require('@/assets/image/apartments.png'),
    enable: true,
    name: 'Apt',
    path: '/propertyIndex/apartment'
}, {
    title: WordList.RDeviceResidents,
    icon: require('@/assets/image/infoResidents.png'),
    enable: true,
    name: 'All',
    path: '/propertyIndex/resident'
}, {
    title: WordList.OrderDevice,
    icon: require('@/assets/image/Devices.png'),
    enable: true,
    name: 'DeviceAll',
    path: '/propertyIndex/device'
}];

// 门禁授权统计图
const countsCharts = (countsData: number[], type: CommunityCountsChartType) => {
    const categories = {
        Resident: [WordList.ProperAllTextPIN, WordList.DeliveryRfCard, WordList.ProperAllTextPhotos, WordList.NavInHtmlMenuApp],
        TempKey: [WordList.ProperAllTextTempKey],
        Delivery: [WordList.ProperAllTextPIN, WordList.DeliveryRfCard],
        Staff: [WordList.DeliveryRfCard]
    };
    Highcharts.chart('counts-container', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories[type]
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{y}'
                }
            },
            // 设置壮壮图的柱宽
            column: {
                pointWidth: 75
            }
        },
        series: [{
            name: '',
            data: countsData,
            type: 'column'
        }],
        colors: ['#18BAFD']
    });
};

export default null;
export {
    countsNav,
    reminderData,
    infoData,
    countsCharts
};
