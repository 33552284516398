
import {
    defineComponent, nextTick, Ref, ref, watch, onBeforeUnmount
} from 'vue';
import '@/components/view/common/dashboard/common.less';
import itemTitle from '@/components/view/common/dashboard/ItemTitle.vue';
import cardIcon from '@/components/view/common/dashboard/CardIcon.vue';
import entryRecord from '@/components/view/common/dashboard/EntryRecord.vue';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import HttpRequest from '@/util/axios.config';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import router from '@/router';
import { community } from '@/data';
import {
    addColor, timesCharts, TimesNav
} from './util';
import {
    countsCharts, countsNav, reminderData, infoData
} from './community';
import {
    ReminderType, CommunityInfoType, CommunityKeyType,
    DoorType, RecordType, CommunityData, AppType,
    CommunityCountsChartType, TimesChartType
} from './all.type';

export default defineComponent({
    components: {
        itemTitle,
        cardIcon,
        secondaryNav,
        entryRecord,
        EnlargeImage
    },
    setup() {
        const interval: Ref<any> = ref(0);
        // 离开当前页面销毁
        onBeforeUnmount(() => {
            clearInterval(interval.value);
        });
        // 卡片数据reminder/info
        const reminderNum: Ref<ReminderType> = ref({
            Offline: 0,
            Inactivated: 0,
            Expiring: 0,
            Expired: 0
        });
        const infoNum: Ref<CommunityInfoType> = ref({
            Building: 0,
            Apt: 0,
            All: 0,
            DeviceAll: 0
        });
        // 统计图数据counts/times
        const counts: Ref<CommunityKeyType> = ref({
            Resident: [],
            TempKey: [],
            Delivery: [],
            Staff: []
        });
        const times: Ref<DoorType> = ref({
            Day: [],
            Month: []
        });

        const logImageMap = new Map<string, string>();

        // 每5秒获取Entry Record数据
        const recordData: Ref<Array<RecordType>> = ref([]);
        const getRecord = () => {
            if (community.enableShowLog.value) {
                HttpRequest.get('v3/web/community/capture/getLastFifteenDoorLog', [{}, false], (res: {
                    data: Array<Omit<RecordType, 'CachedPicUrl'>>;
                }) => {
                    const tempData: Array<RecordType> = [];

                    /**
                     * 缓存图片地址与图片完整 url 的映射，地址相同时无视地址后续参数，取缓存的 url
                     * 避免因防盗链导致的无法缓存
                     */
                    res.data.forEach((item) => {
                        const tempItem: any = { ...item };
                        const url = item.PicUrl.split('?')[0];
                        if (logImageMap.has(url)) {
                            tempItem.CachedPicUrl = logImageMap.get(url) as string;
                        } else {
                            tempItem.CachedPicUrl = item.PicUrl;
                            logImageMap.set(url, item.PicUrl);
                        }
                        tempData.push(tempItem);
                    });

                    recordData.value = tempData;
                });
            }
        };

        // 有 log 权限时才会请求接口
        watch(() => community.enableShowLog, () => {
            getRecord();
        }, {
            immediate: true
        });
        interval.value = setInterval(() => {
            getRecord();
        }, 5000);

        // 获取首页面板其他数据
        HttpRequest.get('v3/web/community/project/getStatData', {}, (res: {
            data: CommunityData;
        }) => {
            const {
                App, Device, Apt, Building, Key, DoorLog
            } = res.data;
            reminderNum.value.Offline = Device.Offline;
            infoNum.value.DeviceAll = Device.All;
            infoNum.value.Apt = Apt;
            infoNum.value.Building = Building;
            Object.keys(App).forEach((val) => {
                if (val !== 'All') {
                    const key = val as keyof AppType;
                    reminderNum.value[key] = App[key];
                } else {
                    infoNum.value.All = App[val];
                }
            });
            counts.value = Key;
            times.value.Day = addColor(DoorLog.Day);
            times.value.Month = addColor(DoorLog.Month);
            nextTick(() => {
                if (document.getElementById('counts-container')) {
                    countsCharts(counts.value.Resident, 'Resident');
                    if (community.enableShowLog.value) {
                        timesCharts(times.value.Day);
                    }
                }
            });
        });

        // 监听counts标签，切换charts统计图
        const activeCountsItem: Ref<CommunityCountsChartType> = ref('Resident');
        watch(activeCountsItem, () => {
            countsCharts(counts.value[activeCountsItem.value], activeCountsItem.value);
        });
        const changeCountsChart = (key: CommunityCountsChartType) => {
            activeCountsItem.value = key;
        };

        // 监听times标签，切换charts统计图
        const activeTimesItem: Ref<TimesChartType> = ref('Day');
        watch(activeTimesItem, () => {
            timesCharts(times.value[activeTimesItem.value]);
        });
        const changeTimesChart = (key: TimesChartType) => {
            activeTimesItem.value = key;
        };

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const enlargeImg = (img: string) => {
            picAddress.value = img;
            isShowEnlargeImage.value = true;
        };

        // 跳转制定页面
        const goPath = (path: string) => {
            router.push(path);
        };

        return {
            countsNav,
            TimesNav,
            changeCountsChart,
            changeTimesChart,
            activeCountsItem,
            activeTimesItem,
            reminderData,
            reminderNum,
            infoData,
            infoNum,
            recordData,
            enlargeImg,
            isShowEnlargeImage,
            picAddress,
            goPath,
            community
        };
    }
});
